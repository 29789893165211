import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {Container} from '@mui/material';
import { useTheme } from '@mui/material/styles';

const TwoColumnInfoBox = ({ title, content }) => {
  const theme = useTheme();
  return (
    // <Paper square elevation={0} sx={{py: 20, backgroundColor: "blue"}}>
    <Container maxWidth='lg' sx={{pt:5}}>
    <Box sx={{ flexGrow: 1, padding: 2 }}>
      <Grid container spacing={{md:2, xs:1}} alignItems="center">
        {/* Title Column */}
        <Grid item xs={12} md={6}>
          <Typography variant="h4" sx={{
            textAlign:{ xs: 'left', md: 'center' },
            color: theme.palette.primary.contrastText
          }}>
            {title}
          </Typography>
        </Grid>

        {/* Content Column */}
        <Grid item xs={12} md={6}>
          <Typography variant="body1" sx={{
            textAlign: { xs: 'left', md: 'left' },
            color: theme.palette.primary.subText
          }}>
            {content}
          </Typography>
        </Grid>
      </Grid>
    </Box>
    </Container>
    // </Paper>
  );
};

export default TwoColumnInfoBox;
