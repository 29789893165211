import './App.css';

import Homepage from './pages/Homepage';
import Footer from './components/Footer/Footer';





function App() {
  return (
      <div maxWidth="lg">
      <Homepage />
      <Footer />
      </div>
  );
}

export default App;
