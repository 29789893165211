import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import styled from '@emotion/styled';
import {Container} from '@mui/material'

import fullLogo from '../../assets/svgs/Launchcraft_logo_full.svg'

import MailOutlineIcon from '@mui/icons-material/MailOutline';
import {mailTo} from '../../utils/Utils';

// Frosted glass effect style
const FrostedAppBar = styled(AppBar)`
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);  // For Safari
  background-color: rgba(18, 35, 46, 0.4);  // Change this to any color you want
`;

export default function Navbar() {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <FrostedAppBar>
      <Container maxWidth="lg">
        <Toolbar>
          <Container sx={{ flexGrow: 1}} disableGutters>
              {/* <Box> */}
              <img src={fullLogo} alt="Icon"
                style={{ height: '50px', cursor: 'pointer'}}
                
                onClick={scrollToTop}/>
              {/* </Box> */}
          </Container>
          <Button color="inherit" onClick={mailTo} endIcon={<MailOutlineIcon />}>
            Contact
          </Button>
        </Toolbar>
      </Container>
    </FrostedAppBar>
  );
}
