import React from 'react';
import Navbar from '../components/Navbar/Navbar';
import MainLanding from '../components/MainLanding/MainLanding';
import TwoColumnInfoBox from '../components/TwoColumnInfoBox/TwoColumnInfoBox';
import TripleBoxSection from '../components/TripleBoxSection/TripleBoxSection';
import ContactSection from '../components/ContactSection/ContactSection';
import { Paper, Box } from '@mui/material';
import logo from '../assets/svgs/Launchcraft_logo.svg'

export default function Homepage() {

  return (
    <>
      <Navbar/>
      
      <MainLanding />

      <Paper square elevation={0} sx={{py: 10, backgroundColor: 'primary.main'}}>
        <TripleBoxSection />
        
        <TwoColumnInfoBox
          title="About Us"
          content="Our core mission is to drive business transformation through innovative cloud and emerging technology solutions. With in-depth experience in deploying solutions for businesses ranging from agile start-ups to established multinationals, our expertise encompasses everything from tailored AWS cloud configurations to the frontiers of blockchain and AI.  We are dedicated to aligning the latest technological innovations with your business objectives, crafting solutions that are not only efficient and secure but also designed for long-term adaptability and scalability. This approach is designed to future-proof your business in a rapidly evolving digital landscape, ensuring long-term success and technological resilience."
        />
      </Paper>
      <ContactSection/>
      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        py:2
      }}>
        <img src={logo} alt="Icon"
          style={{
            height: '40px',
          }}>
        </img>
      </Box>

    </>
  );
}

