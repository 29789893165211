import React from 'react';
import { Paper, Typography, Container } from '@mui/material';
import { useTheme } from '@mui/material/styles';

function Footer() {
    const theme = useTheme();
    return (
        <Paper square elevation={0} outlined sx={{
            marginTop: 'auto',  // pushes the footer to the bottom
            backgroundColor: theme.palette.background.dark

        }}>
            <Container maxWidth='lg' sx={{py:2}}>
                <Typography variant="body2" align="left" fontSize='75%'>
                    © {new Date().getFullYear()} Launchcraft Ltd.
                </Typography>
                <Typography variant="body2" align="left" fontSize='75%'>
                    Registered Office: 71-75 Shelton Street, Covent Garden, London, United Kingdom, WC2H 9JQ 
                </Typography>
                <Typography variant="body2" align="left" fontSize='75%'>
                    Company Registration No: 14493681
                </Typography>
                <Typography variant="body2" align="left" fontSize='75%'>
                    Registered in England and Wales
                </Typography>
                <Typography variant="body2" align="left" fontSize='75%'>
                    VAT Registration No: 433193018
                </Typography>
            </Container>
        </Paper>
        
    );
}

export default Footer;
