import {Box, Button, Container, Grid, Typography} from '@mui/material';
import {mailTo} from '../../utils/Utils';
import { useTheme } from '@mui/material/styles';

export default function ContactSection() {
  const theme = useTheme();
  return (
    <Container maxWidth='lg' sx={{py:  20}}>
    <Box sx={{ flexGrow: 1, padding: 2 }}>
      <Grid container spacing={0} alignItems="center">
        <Grid item xs={12}>
          <Typography gutterBottom variant="h4" align='center' sx={{color: theme.palette.primary.contrastText}}>
            How can we help?
          </Typography>
          <Typography variant="body1" align='center' sx={{color: theme.palette.primary.subText}}>
            Get in touch
          </Typography>
        </Grid>

        <Grid item xs={12} align="center">
          <Button variant="outlined" color="whiteButton" size="large" onClick={mailTo}>
            Contact
          </Button>
        </Grid>
      </Grid>
    </Box>
    </Container>
  );
}