import IconInfoBox from '../IconInfoBox/IconInfoBox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import QueryStatsIcon from '@mui/icons-material/QueryStats';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';


const TripleBoxSection = () => {
  return (
    <Box sx={{padding: 2, display: 'flex',justifyContent: 'center'}}>
      <Grid container rowSpacing={5} columnSpacing={{ md: 5 }} sx={{maxWidth: 1200}}>
        <Grid item sm={12} md={4} sx={{display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <IconInfoBox
            title={'Transformation'}
            content={`Revolutionise your business with our comprehensive digital transformation strategies, leveraging the latest in cloud technology and blockchain to redefine your digital landscape.`}
            icon={PublishedWithChangesIcon}
          />
        </Grid>
        <Grid item sm={12} md={4} sx={{display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <IconInfoBox
            title={'Innovation'}
            content={`At the heart of our approach lies a commitment to innovation - exploring uncharted territories in technology to bring you groundbreaking solutions that set you apart.`}
            icon={EmojiObjectsIcon}
          />
        </Grid>
        <Grid item sm={12} md={4} sx={{display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <IconInfoBox
            title={'Value'}
            content={`Focused on delivering tangible value, our solutions are designed to optimise efficiency, enhance performance, and drive sustainable growth for your business.`}
            icon={QueryStatsIcon}
          />
        </Grid>
      </Grid>

    </Box>
  );
};


export default TripleBoxSection;