import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';


const theme = createTheme({
  typography: {
    fontFamily: [
      'Open Sans',
      'Montserrat',
    ].join(','),
    h2: {
      fontFamily: 'Montserrat',
      fontWeight: 600,
    },
    h3: {
      fontFamily: 'Montserrat'
    },
    h4: {
      fontFamily: 'Montserrat'
    },
    h5: {
      fontFamily: 'Montserrat'
    },
    h6: {
      fontFamily: 'Montserrat'
    },
    body1: {
      fontFamily: 'Open Sans',
    },
    body2: {
      color: 'rgba(220, 238, 251, 0.5)',
      fontFamily: 'Open Sans',
    },
  },
  palette: {
    background: {
      default: '#102A43',
      dark: '#091b2b',
    },
    primary: {
      main: '#334E68',
      light: '#486581',
      dark: '#243B53',
      contrastText: 'rgb(220, 238, 251)',
      subText: 'rgba(220, 238, 251, 0.8)',
    },
    secondary: {
      main: '#829AB1',
      light: '#9FB3CB',
      dark: '#627D98',
    },
    text: {
      primary: 'rgb(220, 238, 251)',
      secondary: '#FF0000',
    },
    whiteButton: {
      main: 'rgb(220, 238, 251)',
      light: '#486581',
      dark: '#243B53',
      contrastText: 'rgb(220, 238, 251)',
    },
  }
});

// NEUTRALS
// #102A43
// #243B53
// #334E68
// #486581
// #627D98
// #829AB1
// #9FB3CB
// #BCCCDC
// #D9E2EC
// #F0F4F8

// PRIMARY
// #003E68
// #0A558C
// #0F609B
// #186FAF
// #2680C2
// #4098D7
// #62B0E8
// #84C5F4
// #B6E0FE
// #DCEEFB

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <App />
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
