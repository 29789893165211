import React from 'react';
import { Typography, Paper } from '@mui/material';
import { useTheme } from '@mui/material/styles';



const IconInfoBox = ({icon: Icon, title, content}) => {
  const theme = useTheme();
//   const FrostedPaper = styled(Paper)`
//   backdrop-filter: blur(4px);
//   -webkit-backdrop-filter: blur(4px);  // For Safari
//   background-color: rgba(18, 35, 46, 0.4);  // Change this to any color you want
// `;

  return (

    <Paper elevation={0} sx={{
      p: 2,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      height: '100%', // Makes sure Paper fills the container height
      maxWidth: {sm: 700, md: 400}, // Set a maxWidth for each component
      backgroundColor: 'primary.dark',
    }} >
      <Icon sx={{mt: 2, mb:1,  fontSize: 40, color: theme.palette.primary.contrastText }} />
      <Typography variant="h5"  sx={{
        // mb:3,
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: theme.palette.primary.contrastText
      }}>
        {title}
      </Typography>
      <Typography component="body1" sx={{
        my:1,
        textAlign: 'center',
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'stretch',
        color: theme.palette.primary.subText
      }}>
        {content}
      </Typography>
    </Paper>
  );
};

export default IconInfoBox;
