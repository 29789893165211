import React from 'react';
import { Box,Container, Typography } from '@mui/material';


// NEUTRALS
// #102A43
// #243B53
// #334E68
// #486581
// #627D98
// #829AB1
// #9FB3CB
// #BCCCDC
// #D9E2EC
// #F0F4F8

// PRIMARY
// #003E68
// #0A558C
// #0F609B
// #186FAF
// #2680C2
// #4098D7
// #62B0E8
// #84C5F4
// #B6E0FE
// #DCEEFB

function FullPageComponent() {
  return (
        <Container maxWidth="md" component="section" sx={{
          minHeight: '80vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          mt: 5 }}>
          <Box>
            <Typography variant="h2" gutterBottom 
              sx={{
                background: 'radial-gradient(circle at top, #84C5F4, #4098D7)',
                WebkitBackgroundClip: 'text',
                backgroundClip: 'text',
                color: 'transparent',}}
            >
              Elevating Enterprises to the Clouds and Beyond
            </Typography>
            <Typography variant="h5" color="text">
              Navigate the Future of Cloud and Technology with Launchcraft
            </Typography>
          </Box>
        </Container>
  );
}

export default FullPageComponent;
